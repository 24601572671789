import React from 'react';
import PropTypes from 'prop-types';
import './hero.scss';

const Hero = ({ title, subtitle }) => (
  <div className="h-100 d-flex align-items-center justify-content-center text-white hero bg-primary angled-edges">
    <div className="container">
      <div className="text-center">
        <h1>{title}</h1>
        {subtitle ? <h3>{subtitle}</h3> : null}
      </div>
    </div>
  </div>
);
Hero.prototype = {
  title: PropTypes.isRequired,
  subtitle: PropTypes.isRequired
};
export default Hero;
