import React from 'react';
import Layout from '../components/layout/layout';
import Hero from '../components/hero/hero';
import { Link } from 'gatsby';

const About = () => (
  <Layout>
    <Hero title="À Propos du Centre" />
    <div className="container py-5">
      <div className="mb-3 card">
        <div className="card-body">
          <h3>Présentation du Centre Papillon Bleu</h3>
          <p>
            Le Centre Papillon Bleu est une structure d'accueil et d'encadrement pédagogique des enfants déficients
            mentaux et des enfants handicapés moteurs.
          </p>
          <p>
            Les difficiles conditions de vie de ces enfants souvent marginalisés, ont motivées l'ouverture de centre
            pour la ré-insertion sociale de ces enfants. C'est ainsi que le Centre Papillon Bleu a vu le jour en 2007.
          </p>
          <p>
            Aujourd'hui le centre reçoit <strong>50</strong> enfants de façons régulières en plus des{' '}
            <strong>200 </strong> enfants répertoriés pour qui les parents, par manque de moyens, ne peuvent pas assurer
            le déplacement quotidien.
          </p>
          <p>
            L'âge des enfants varie entre <strong>4</strong> et <strong>17</strong> ans.
          </p>
          <p>
            En raison de la pauvreté qui s'accentue de jour en jour, l'objectif du centre est de faire en sorte que les
            familles trouvent un lieu d'accueil où les enfants déficients, souvent incompris, sont préparés pour la vie
            en société.
          </p>
          <p>
            Actuellement, le Centre Papillon Bleu soutient des enfants et jeunes adolescents handicapés en leur
            fournissant, entre autres activités, des soins, de l'éducation, de la formation professionnelle, de
            l'assistance médicale et un renforcement nutritionnel.
          </p>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 text-center p-5 bg-primary">
            <h4 className="text-white">Des questions sur le Centre Papillon Bleu ?</h4>
            <Link className="text-uppercase" to="/contact">
              <button className="btn bg-white">Contactez nous</button>
            </Link>
          </div>
          <div className="col-12 col-md-6 text-center p-5 bg-secondary">
            <h4 className="text-white">Aidez le Centre Papillon Bleu</h4>
            <Link className="text-uppercase" to="/contact">
              <button className="btn bg-white">Faire un don</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default About;
